import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PayFeesRoutingModule} from './pay-fees-routing.module';
import {PayFeesComponent} from './pay-fees.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
    declarations: [PayFeesComponent],
    imports: [
        CommonModule,
        PayFeesRoutingModule,
        NgbPaginationModule,
        ReactiveFormsModule,
        SharedModule
    ]
})

export class PayFeesModule {
}
