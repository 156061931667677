import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PayFeesService} from '../../shared/services/pay-fees.service';
import {StorageService} from '../../shared/services/storage.service';

@Component({
    selector: 'app-pay-fees',
    templateUrl: './pay-fees.component.html',
    styleUrls: ['./pay-fees.component.scss']
})
export class PayFeesComponent implements OnInit {
    showSearch = false;
    transactions = [];
    data: any;
    updateStatusPayFeeForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    payFeeTotalAmount: any;
    user = StorageService.getItem('self');
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private payFeesService: PayFeesService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.updateStatusPayFeeForm = this.fb.group({
            'status': ['', Validators.required]
        });
        this.searchForm = this.fb.group({
            'name': [''],
            'order_id': [''],
            'amount': [''],
            'contact': [''],
            'status': [''],
        });
        this.get();
        if (this.user && this.user['user_type'] === 'SUPER_ADMIN') {
            this.getTotalAmount();
        }
    }

    updateStatusModal(content, data) {
        this.data = data;
        this.updateStatusPayFeeForm.patchValue({
            'status': ''
        });
        this.modalService.open(content, {'size': 'sm'});
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.transactions = [];
        this.payFeesService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.transactions = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.transactions = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    getTotalAmount() {
        this.payFeesService.getTotalAmount({}).subscribe(data => {
            this.payFeeTotalAmount = data;
        });
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'order_id': (this.searchForm.value && this.searchForm.value.order_id) ? this.searchForm.value.order_id : '',
            'amount': (this.searchForm.value && this.searchForm.value.amount) ? this.searchForm.value.amount : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : '',
            'status': (this.searchForm.value && this.searchForm.value.status) ? this.searchForm.value.status : ''
        };
        this.get(params);
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm && this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm && this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm && this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : ''
        };
        this.get(params);
    }

    updatePayFeeStatus() {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': (this.updateStatusPayFeeForm && this.updateStatusPayFeeForm.value && this.updateStatusPayFeeForm.value['status']) ? this.updateStatusPayFeeForm.value['status'] : ''
            };
            this.submitting = true;
            this.payFeesService.updateStatus(this.data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.data = undefined;
                this.modalService.dismissAll('');
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.data = undefined;
            });
        }
    }

    downloadReceipt(data) {
        let url = 'https://distance.sgvu.org/download.html?order_id=' + data['order_id'];
        window.open(url, '_blank');
    }
}
