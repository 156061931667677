import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PayFeesComponent} from './pay-fees.component';

const routes: Routes = [
  {
    path: 'pay-fees',
    component: PayFeesComponent,
    data: {animation: 'my-profile'}
  }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class PayFeesRoutingModule {
}
